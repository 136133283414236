@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

$space-big: 20px;
$space-medium: 15px;

.wrapper {
  width: 100%;
  border: 1px solid $color-border-light;
  padding-top: $space-big;

  @include md-min {
    max-width: 490px;
  }
}
